

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'

interface Nav {
  [key: string]: string | Nav;
}

interface CP {
  [key: string]: boolean;
}

@Component({
  name: 'sidebar',
  computed: {
    ...mapGetters(['activeUrl']),
    ...mapState(['active', 'scrollTop'])
  }
})
export default class Sidebar extends Vue {
  @Prop() private navs!: Nav
  @Prop() private type!: 'sub' | undefined
  @Prop({ default: () => [] }) private parents!: string[]
  collapsed: CP = {}
  activeUrl!: string
  scrollTop!: number
  active!: string[]

  mounted() {
    if (this.type !== 'sub') {
      console.log('this.activeUrl', this.activeUrl)
      const listener = () => {
        if (this.scrollTop) {
          this.scrollTo();
          (this.$refs.sideBar as Element).removeEventListener('transitionend', listener)
        }
      }
      (this.$refs.sideBar as Element).addEventListener('transitionend', listener, false)
    }
    const active = this.activeUrl.split('/').pop()
    if (active && Object.values(this.navs).includes(active)) {
      console.log((this.$refs.active as Element[])[0].getBoundingClientRect().top)
      this.$store.commit('SET_SCROLL_TOP', (this.$refs.active as Element[])[0].getBoundingClientRect().top)
    }
    Object.keys(this.navs).map(k => {
      this.collapsed[k] = false
    })
    this.onNavsChange()
  }

  dirClick(label: string, changeUrl = true) {
    this.collapsed[label] = !this.collapsed[label]
    const url = this.parents.concat([label]).join('/')
    console.log('url:', url)
    if (changeUrl) {
      history.pushState({}, '', `${location.origin}${location.pathname}#${url}/`)
    }
    this.$forceUpdate()
  }

  fileClick(label: string) {
    const url = this.parents.concat([label]).join('/')
    history.pushState({}, '', `${location.origin}${location.pathname}#${url}`)
    this.$store.dispatch('getContent', this.parents.concat([label]))
    this.$forceUpdate()
  }

  @Watch('navs')
  onNavsChange() {
    Object.keys(this.navs).map(k => {
      this.collapsed[k] = false
    })
    const dir = this.active.concat() || []
    let activity = ''
    while (this.parents.length < dir.length) {
      activity = dir.pop() || ''
    }
    console.log('activity:', activity)
    if (activity && dir.join('') === this.parents.join('')) {
      const item = Object.keys(this.collapsed).find(k => k === activity) || ''
      this.dirClick(item, false)
    }
  }

  scrollTo() {
    (this.$refs.sideBar as Element).scrollTo({ top: this.scrollTop })
  }
}
