// create an axios instance   创建axios实例
import axios from 'axios'
import store from '@/store'

let requestingCount = 0

const service = axios.create({
  baseURL: '/', // api 的 base_url
  timeout: 15000, // request timeout  设置请求超时时间
  withCredentials: true, // 是否允许带cookie这些
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
})// create an axios instance
service.interceptors.request.use(
  config => {
    if (!requestingCount) store.commit('loading')
    requestingCount++
    return config
  }
)
service.interceptors.response.use(
  response => {
    requestingCount--
    if (!requestingCount) store.commit('loaded')
    return Promise.resolve(response)
  },
  (error) => {
    requestingCount--
    if (!requestingCount) store.commit('loaded')
    console.error(error)
    console.log(error.config)
    return Promise.reject(error)
    // if (error.code === 'ECONNABORTED') {
    //   // 中止，超时
    //   // return resolve(axiosAutoTry(data))
    // }
    // return Promise.reject(error)
  }
)

export default service
