import Vuex from 'vuex'
import marked from 'marked'
import Vue from 'vue'
import service from '@/request'

interface Translate {
  src: string;
  tgt: string;
}

export interface Bookmark {
  id: number;
  user_id: number;
  url: string;
  created_at: string;
  updated_at: string;
}

export interface Option {
  name: string;
  label: string;
  value: boolean;
}

export interface Tooltip {
  src: string;
  dst: string;
}

export type User = Record<string, number | string> | undefined | null

export interface RootState {
  active: string[];
  retry: string[];
  bookmarks: Bookmark[];
  content: string;
  retryMessage: string;
  settingOptions: Option[];
  loading: boolean;
  user: User;
  scrollTop: number;
  error: string;
  tooltips: Tooltip[];
}

let tooltips
try {
  tooltips = JSON.parse(localStorage.getItem('tooltips') as string) || []
} catch (e) {
  tooltips = []
}
Vue.use(Vuex)
const store = new Vuex.Store<RootState>({
  state: {
    active: [],
    retry: [],
    bookmarks: [],
    tooltips,
    retryMessage: '',
    content: '',
    settingOptions: [
      {
        name: 'auto-translate',
        label: '自动翻译',
        value: false
      }
      // {
      //   name: 'replace-translate',
      //   label: '替换翻译',
      //   value: false
      // },
      // {
      //   name: 'content-editable',
      //   label: '内容可编辑',
      //   value: false
      // }
    ],
    loading: false,
    user: null,
    scrollTop: 0,
    error: ''
  },
  mutations: {
    setActive(state, active) { state.active = active },
    setContent(state, { content, type }) {
      if (state.tooltips.length > 0) {
        state.tooltips.forEach(value => {
          content = content.replace(new RegExp(value.src, 'g'), `<span class="novel-tooltip" data-title="${value.dst}">${value.src}</span>`)
        })
      }
      if (type === 'html') {
        state.content = content
      } else if (type === 'md') {
        state.content = marked(content)
        // } else if (type === 'txt') {
        //   state.content = '<p class="text">' + content.replace(/\n/g, '</p>\n<p class="text">') + '</p>'
      } else {
        state.content = '<p class="text">' + content.replace(/\n/g, '</p>\n<p class="text">') + '</p>'
      }
    },
    setSettingOptions(state, payload) {
      localStorage.setItem('settings', JSON.stringify(Object.fromEntries(state.settingOptions.map(s => [s.name, s.value]))))
      state.settingOptions = payload
    },
    loading(state) { state.loading = true },
    loaded(state) { state.loading = false },
    requestTimeout(state, { type, message = '' }: { type: string; message: string }) {
      state.retry.push(type)
      state.retryMessage = message
    },
    clearRetry(state) { state.retry = [] },
    SET_USER(state, payload) { state.user = payload },
    SET_ERROR(state, payload) { state.error = payload },
    // 书签
    SET_BOOKMARKS(state, payload) { state.bookmarks = payload },
    APPEND_BOOKMARK(state, payload) { state.bookmarks.push(payload) },
    DEL_BOOKMARK(state, id) { state.bookmarks.splice(state.bookmarks.findIndex(i => i.id === id), 1) },
    // 标注
    SET_TOOLTIPS(state, payload) {
      state.tooltips = payload
      localStorage.setItem('tooltips', JSON.stringify(state.tooltips))
    },
    APPEND_TOOLTIPS(state, payload) {
      state.tooltips.push(payload)
      localStorage.setItem('tooltips', JSON.stringify(state.tooltips))
    },
    DEL_TOOLTIPS(state, payload) {
      state.tooltips.splice(state.tooltips.indexOf(payload), 1)
      localStorage.setItem('tooltips', JSON.stringify(state.tooltips))
    },

    SET_SCROLL_TOP(state, scrollTop) { state.scrollTop = scrollTop }
  },
  getters: {
    activeUrl: state => state.active.join('/')
  },
  actions: {
    async getContent({ commit, state, dispatch }, active?: string[]) {
      console.log(active)
      if (!active) {
        active = state.active
      } else {
        commit('setActive', active)
      }
      service.post('/util.php', { m: 'getFile', href: active.join('\\') }).then(({ data }) => {
        console.log(data)
        commit('setContent', { content: data.data, type: (active as string[]).concat().pop()?.split('.').pop() })
        if (state.settingOptions.find(s => s.name === 'auto-translate')?.value === true) {
          dispatch('getTranslate')
        }
      }).catch(error => {
        console.error(error)
        console.log(error.config)
        commit('requestTimeout', { type: 'getContent' })
      })
    },
    getTranslate({ commit, state }, payload) {
      const postData = {
        m: 'getTranslate',
        content: state.active.join('\\'),
        force: payload
      }
      console.log(state.active)
      service.post<{ data: Translate[] }>('/util.php', postData).then(({ data }) => {
        console.log(data)
        let content = ''
        data.data.forEach(value => {
          if (!value.src) return
          content += `<p class="text">${value.src}</p><p class="text translate">${value.tgt}</p>`
        })
        commit('setContent', { content, type: 'html' })
      }).catch(error => {
        console.log(error)
        console.log(error.config)
        commit('requestTimeout', { type: 'getTranslate' })
      })
    },
    retry({ commit, state, dispatch }) {
      const { retry } = state
      retry.forEach(value => {
        dispatch(value)
      })
      commit('clearRetry')
    },
    login({ commit, dispatch }, payload: { username: string; password: string }) {
      return new Promise((resolve, reject) => {
        service.post('/util.php', { ...payload, m: 'login' }).then(({ data }) => {
          if (data.code > 0) {
            commit('SET_USER', data.data)
            commit('SET_ERROR', '')
            dispatch('getBookmarks')
            resolve(data.data)
          } else {
            commit('SET_ERROR', data.message)
            reject(data.message)
          }
        })
      })
    },
    register({ commit }, payload: { username: string; password: string }) {
      return new Promise((resolve, reject) => {
        service.post('/util.php', { ...payload, m: 'register' }).then(({ data }) => {
          if (data.code > 0) {
            commit('SET_USER', data.data)
            commit('SET_ERROR', '')
            resolve(data.data)
          } else {
            commit('SET_ERROR', data.message)
            reject(data.message)
          }
        })
      })
    },
    getUserInfo({ commit }) {
      service.post('/util.php', { m: 'user' }).then(({ data }) => {
        if (data.code > 0) {
          commit('SET_USER', data.data)
        }
      })
    },
    logout({ commit }) {
      service.post('/util.php', { m: 'logout' }).then(({ data }) => {
        if (data.code > 0) {
          commit('SET_USER', null)
        }
      })
    },
    getBookmarks({ commit }) {
      service.post('/util.php', { m: 'getBookmarks' }).then(({ data }) => {
        if (data.code > 0) {
          commit('SET_BOOKMARKS', data.data)
        }
      })
    },
    addBookmark({ commit }, url) {
      return new Promise((resolve, reject) => {
        service.post('/util.php', { m: 'addBookmark', url }).then(({ data }) => {
          if (data.code > 0) {
            commit('APPEND_BOOKMARK', data.data)
            resolve(data.data)
          } else {
            reject(data.message)
          }
        })
      })
    },
    delBookmark({ commit }, id) {
      service.post('/util.php', { m: 'delBookmark', id }).then(({ data }) => {
        if (data.code > 0) {
          commit('DEL_BOOKMARK', id)
        }
      })
    },
    setScrollTop({ commit }, scrollTop) {
      commit('SET_SCROLL_TOP', scrollTop)
    }
  }
})

export default store
