















import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'retry' })
export default class Retry extends Vue {
}
