















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Sidebar from './components/Sidebar.vue'
import marked from 'marked'
import { mapState } from 'vuex'
import service from '@/request'
import LdsLoading from '@/components/LdsLoading.vue'
import Modal from '@/components/Modal.vue'
import Retry from '@/components/Retry.vue'
import { Bookmark, Option, RootState, Tooltip, User } from '@/store'

type ShowModalOption = {
  title: string;
  msg: string;
}

@Component({
  components: {
    Retry,
    Modal,
    LdsLoading,
    Sidebar
  },
  computed: {
    ...mapState(['content', 'settingOptions', 'user', 'tooltips']),
    ...mapState({ onlineBookmarks: s => (s as RootState).bookmarks })
  }
})
export default class App extends Vue {
  tmpTooltip: Tooltip = {
    src: '',
    dst: ''
  }

  marked = marked
  leftBarOpened = true
  toolBarOpened = false
  isShowModal = false
  showCreateTooltip = false
  showLogin = false
  bookmarks: string[] = []
  onlineBookmarks!: Bookmark[]
  tooltips!: Tooltip[]
  navs = []
  settingOptions!: Option[]
  user!: User
  modalTitle = ''
  modalMsg = ''
  forceTranslate = false
  form = {
    nickname: '',
    code: '',
    username: '',
    password: ''
  }

  mounted() {
    let settings: { [key: string]: boolean }
    try {
      settings = JSON.parse(localStorage.getItem('settings') || 'null') || []
      this.bookmarks = JSON.parse(localStorage.getItem('bookmarks') || '[]') || []
    } catch (e) {
      settings = {}
      this.bookmarks = []
    }
    console.log(settings)
    const s = this.settingOptions.map(s => {
      s.value = settings[s.name] ? settings[s.name] : false
      return s
    })
    console.log(s)
    this.$store.commit('setSettingOptions', s)
    this.getNav()
    window.onpopstate = async(event: PopStateEvent) => {
      console.log('location: ' + decodeURI(document.location.toString()) + ', state: ' + JSON.stringify(event.state))
      const active = decodeURI(location.hash.substring(1)).split('/')
      if (!location.hash.substring(1) || location.hash.substring(1).endsWith('/')) {
        this.$store.commit('setActive', active)
      } else {
        await this.$store.dispatch('getContent', active)
      }
    }
    this.$store.dispatch('getUserInfo')
    this.$store.dispatch('getBookmarks')
  }

  async getNav() {
    const active = decodeURI(location.hash.substring(1)).split('/')
    if (!location.hash.substring(1) || location.hash.substring(1).endsWith('/')) {
      this.$store.commit('setActive', active)
    } else {
      await this.$store.dispatch('getContent', active)
    }
    service.get('/test.json').then(({ data }) => {
      this.navs = data
      console.log(data)
    })
  }

  @Watch('content')
  onContentChange() {
    (this.$refs.mainFrame as HTMLDivElement).scrollTop = 0
  }

  @Watch('bookmarks')
  onBookmarksChange() {
    localStorage.setItem('bookmarks', JSON.stringify(this.bookmarks))
  }

  updateSetting(index: number, event: Event) {
    const s = this.settingOptions.concat()
    s[index].value = (event.target as HTMLInputElement)?.checked
    this.$store.commit('setSettingOptions', s)
  }

  addBookMark() {
    const activeUrl = decodeURI(location.hash.substring(1))

    if (!activeUrl || activeUrl.endsWith('/')) {
      this.showModal({ title: '提示', msg: '书签必须是一个文件' })
      return
    } else if (this.bookmarks.indexOf(activeUrl) !== -1) {
      this.showModal({ title: '提示', msg: '已经在书签里了' })
      return
    }
    if (this.user) {
      this.$store.dispatch('addBookmark', activeUrl).catch(err => {
        this.showModal({ title: '提示', msg: err })
      })
    } else {
      this.bookmarks.push(activeUrl)
    }
  }

  removeBookmark(index: number) {
    this.bookmarks.splice(index, 1)
  }

  removeOnlineBookmark(id: number) {
    this.$store.dispatch('delBookmark', id)
  }

  showModal({ title, msg }: ShowModalOption) {
    this.modalTitle = title
    this.modalMsg = msg
    this.isShowModal = true
  }

  login() {
    this.$store.dispatch('login', this.form).then(() => {
      this.showModal({ title: '提示', msg: '登录成功' })
      this.showLogin = false
    })
  }

  register() {
    this.$store.dispatch('register', this.form).then(() => {
      this.showModal({ title: '提示', msg: '注册成功' })
      this.showLogin = false
    })
  }

  logout() {
    this.$store.dispatch('logout', this.form).then(() => {
      this.showModal({ title: '提示', msg: '退出成功' })
      this.showLogin = false
    })
  }

  addTooltip() {
    this.tmpTooltip = {
      src: '',
      dst: ''
    }
    this.showCreateTooltip = true
  }

  removeTooltip(t: Tooltip) {
    this.$store.commit('DEL_TOOLTIPS', t)
  }

  createTooltip() {
    this.$store.commit('APPEND_TOOLTIPS', this.tmpTooltip)
    this.showCreateTooltip = false
    this.$store.dispatch('getContent')
  }
}
