import Vue from 'vue'
import App from './App.vue'
import '@/scss/index.scss'
import '@/css/checkbox.css'
import '@/css/lds-spinner.css'
import '@/css/sidebar.css'
import 'animate.css'
import store from '@/store'
// Vue.use(animated)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store
}).$mount('#app')
